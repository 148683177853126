import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { makeStyles, ThemeProvider, useTheme } from "@material-ui/core";
import SideMenu from "./navigation/SideMenu";
import { useMenu } from "../context/menu-context";
import Profile from "./profile/Profile";
import { useConfiguration } from "../context/configuration-context";
import InterventionDetail from "./intervention/detail/InterventionDetail";
import LatestIntervention from "./intervention/detail/LatestIntervention";
import InterventionProposal from "./intervention/proposal/InterventionProposal";
import InterventionInvoice from "./intervention/invoice/InterventionInvoice";
import AppConstant from "../utils/appConstant";
import {
  InterventionList,
  Contact,
  Dashboard,
  ListComponents,
  NewIntervention,
} from "./pages";
import InterventionInvoiceDiffered from "./intervention/invoice-differed/InterventionInvoiceDiffered";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0",
    display: "flex",
    minHeight: "100vh",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "100%",
    boxSizing: "border-box",
  },
}));

const AuthenticatedApp = () => {
  const classes = useStyles();
  const menu = useMenu();
  const configuration = useConfiguration();
  const [containerWidth, setContainerWidth] = useState("100vw");
  const theme = useTheme();

  useEffect(() => {
    let drawerWidth = 0;
    if (!menu.onSmartphone) {
      drawerWidth = menu.isOpen
        ? AppConstant.drawerWidth
        : AppConstant.drawerWidthClose;
    }
    setContainerWidth(`calc(100vw - ${drawerWidth}px)`);
  }, [menu.isOpen, menu.onSmartphone, theme]);

  return (
    <div className="App">
      <div className={classes.root}>
        <Router>
          <ThemeProvider theme={configuration.themes.menuTheme}>
            <SideMenu open={menu.isOpen} />
          </ThemeProvider>
          <main
            className={classes.content}
            style={{ maxWidth: containerWidth }}
          >
            <Switch>
              <Route exact path="/">
                <Dashboard />
              </Route>
              <Route path="/intervention/ongoing">
                <InterventionList variant="ongoing" />
              </Route>
              <Route path="/intervention/history">
                <InterventionList variant="closed" />
              </Route>
              <Route path="/intervention/new">
                <NewIntervention />
              </Route>
              <Route exact path="/intervention/:id">
                <InterventionDetail />
              </Route>
              <Route path="/intervention/latest">
                <LatestIntervention />
              </Route>
              <Route exact path="/intervention/:id/proposal">
                <InterventionProposal />
              </Route>
              <Route exact path="/intervention/:id/invoice">
                <InterventionInvoice />
              </Route>
              <Route exact path="/intervention/:id/invoice-differed">
                <InterventionInvoiceDiffered />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              {process.env.REACT_APP_ENVIRONMENT === "development" && (
                <Route path="/components">
                  <ListComponents />
                </Route>
              )}
              <Route>
                <Redirect to="/" />
              </Route>
            </Switch>
          </main>
        </Router>
      </div>
    </div>
  );
};

export default AuthenticatedApp;
