import { Box, Button } from "@material-ui/core";
import React from "react";
import { useAlert } from "react-alert";
import API from "../../../services/api";
import LocationCityIcon from "@material-ui/icons/LocationCity";

const DataExample = () => {
  const alert = useAlert();

  const handleEntityClick = async () => {
    await new API()
      .get("/supercotrolia/debug/entity", (status, data) => data)
      .then((res) => {
        let entity = "Entité numéro " + res.entity;
        let origin = "Origin : " + res.origin ? res.origin : "Aucune";
        alert.show(origin, {
          title: entity,
          close: () => {
            alert.remove(alert);
          },
          type: "success",
        });
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      <Box marginTop={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<LocationCityIcon />}
          onClick={handleEntityClick}
        >
          Quel est mon entité ?
        </Button>
      </Box>
    </>
  );
};

export default DataExample;
