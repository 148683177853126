import React, { useState } from "react";

import {
  Typography,
  Box,
  FormHelperText,
  Divider,
  Link,
  Chip,
} from "@material-ui/core";
import DeveloperModeIcon from "@material-ui/icons/DeveloperMode";

import SyntaxHighlighter from "react-syntax-highlighter";
import { rainbow } from "react-syntax-highlighter/dist/esm/styles/hljs";

import Header from "../../core/Header";
import Content from "../../core/layout/Content";
import ContentCard from "../../core/layout/ContentCard";

import CotroliaAutocomplete from "../../core/input/CotroliaAutocomplete";
import CotroliaFormControl from "../../core/input/CotroliaFormControl";
import DocumentationPropsTable from "../DocumentationPropsTable";
import { categoryDefinition } from "../DocumentationConstant";

const exampleValue = [
  "🍎 Pomme",
  "🍌 Banane",
  "🍐 Poire",
  "🍑 Pêche",
  "🍊 Orange",
  "🍋 Citron",
  "🍉 Pastèque",
];

const inputExampleSimple = `
const Component = () => {
    // State that will store the result
    const [value, setValue] = useState("");

    // Values available for the autocomplete 
    const exampleValue = [
        "🍎 Pomme",
        "🍌 Banane",
        "🍐 Poire",
        "🍑 Pêche",
        "🍊 Orange",
        "🍋 Citron",
        "🍉 Pastèque",
    ];
    
    // Function that will be called when the input changes.
    // It must return a Promise.
    const getSuggestions = (value) => {
        return new Promise((resolve, reject) => {
          resolve(
            exampleValue.filter((item) =>
              item.toLowerCase().includes(value.toLowerCase())
            )
          );
        });
    };
    
    return (
        <CotroliaFormControl>
            <CotroliaAutocomplete
                placeholder="Rechercher un fruit..."
                value={value}
                onChange={setValue}
                getSuggestions={getSuggestions}
            />
            <FormHelperText>Votre recherche est : {value}</FormHelperText>
        </CotroliaFormControl>
    );
}
`;

const inputExampleObject = `
const Component = () => {
    // State that will store the result
    const [value, setValue] = useState({
      street: "",
      city: "",
      zipCode: "",
      label: "",
    });
    
    // Function that will be called when the input changes.
    // It must return a Promise.
    const getSuggestions = (value) => {
      return new Promise((resolve, reject) => {
        fetch(\`https://api-adresse.data.gouv.fr/search/?q=\${value}\`)
          .then((res) => res.json())
          .then((res) => {
            let suggestions = [];
            res.features.forEach((item) => {
              let entry = {};
              entry.street = item.properties.name;
              entry.city = item.properties.city;
              entry.zipCode = item.properties.postcode;
              entry.label = item.properties.label;
              suggestions.push(entry);
            });
            resolve(suggestions);
          })
          .catch((err) => reject(err));
      });
    };
    
    return (
      <CotroliaFormControl>
        <CotroliaAutocomplete
          placeholder="Rechercher une adresse..."
          value={objectValue}
          onChange={setObjectValue}
          getSuggestions={getObjectSuggestions}
          isSuggestionObject
          labelKey="label"
        />
        <FormHelperText>
          Votre recherche est : {JSON.stringify(objectValue)}
        </FormHelperText>
      </CotroliaFormControl>
    );
}
`;

const errorExample = `
const Component = () => {
  return (
    <CotroliaFormControl>
      <CotroliaAutocomplete
        placeholder="Rechercher une adresse..."
        value={value}
        onChange={setValue}
        getSuggestions={getSuggestions}
        error={true}
        helperText="Vous devez saisir une adresse"
      />
    </CotroliaFormControl>
  );
};
`;

const DocAutocomplete = () => {
  const [value, setValue] = useState("");
  const [objectValue, setObjectValue] = useState({
    street: "",
    city: "",
    zipCode: "",
    label: "",
  });

  const getSuggestions = (value) => {
    return new Promise((resolve, reject) => {
      resolve(
        exampleValue.filter((item) =>
          item.toLowerCase().includes(value.toLowerCase())
        )
      );
    });
  };

  const getObjectSuggestions = (value) => {
    return new Promise((resolve, reject) => {
      fetch(`https://api-adresse.data.gouv.fr/search/?q=${value}`)
        .then((res) => res.json())
        .then((res) => {
          let suggestions = [];
          res.features.forEach((item) => {
            let entry = {};
            entry.street = item.properties.name;
            entry.city = item.properties.city;
            entry.zipCode = item.properties.postcode;
            entry.label = item.properties.label;
            suggestions.push(entry);
          });
          resolve(suggestions);
        })
        .catch((err) => reject(err));
    });
  };

  return (
    <>
      <Header
        focus={true}
        title="Composant"
        titleIcon={<DeveloperModeIcon />}
        titleDesc="<CotroliaAutocomplete />"
      ></Header>
      <Content>
        <ContentCard>
          <Typography variant="h5">
            CotroliaAutocomplete : Le composant d'auto-complétion
          </Typography>
          <Box style={{ margin: "1rem 0 1rem 0" }}>
            <Chip
              label="Inputs"
              icon={categoryDefinition["Inputs"].icon}
              variant="outlined"
              color="primary"
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography variant="h5">🚀 Présentation</Typography>
            <Typography variant="subtitle1">
              <p>
                Ce composant est utilisé pour proposer une auto-complétion dans
                une liste. Il est principalement utilisé pour chercher une
                valeur dans un dictionnaire Dolibarr ou dans un service externe
                comme une api REST.
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">📦 Emplacement</Typography>

            <Typography variant="subtitle1">
              <p>
                Ce composant est disponible dans le dossier suivant :{" "}
                <div style={{ overflowX: "auto", width: "100%" }}>
                  <code>
                    src/components/core/input/CotroliaAutocomplete.jsx
                  </code>
                </div>
              </p>
            </Typography>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Auto-complétion basique</Typography>

            <Typography variant="subtitle1">
              <p>
                La première utilisation est de pouvoir proposer une liste de
                chaines de caractères. La valeur sera alors la chaine de
                caractère sélectionnée.
              </p>
            </Typography>

            <CotroliaFormControl>
              <CotroliaAutocomplete
                placeholder="Rechercher un fruit..."
                value={value}
                onChange={setValue}
                getSuggestions={getSuggestions}
              />
              <FormHelperText>Votre recherche est : {value}</FormHelperText>
            </CotroliaFormControl>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {inputExampleSimple}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Auto-complétion d'objet</Typography>

            <Typography variant="subtitle1">
              <p>
                La deuxième utilisation est de pouvoir proposer une liste
                d'objet. Cette manière de faire sera souvent utilisé pour les
                dictionnaires Dolibarr. En effet, l'utilisateur recherche un
                libellé mais nous devons envoyer un id à l'api. La valeur sera
                alors un objet. Dans l'exemple suivant, on appliquera cela à la
                recherche d'une adresse.
              </p>
            </Typography>

            <CotroliaFormControl>
              <CotroliaAutocomplete
                placeholder="Rechercher une adresse..."
                value={objectValue}
                onChange={setObjectValue}
                getSuggestions={getObjectSuggestions}
                isSuggestionObject
                labelKey="label"
              />
              <FormHelperText>
                Votre recherche est : {JSON.stringify(objectValue)}
              </FormHelperText>
            </CotroliaFormControl>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {inputExampleObject}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🔬 Affichage des erreurs</Typography>

            <Typography variant="subtitle1">
              <p>
                Il possible d'afficher le champ en erreur avec les propriétés{" "}
                <code>error</code> et <code>helperText</code>.
              </p>
            </Typography>

            <CotroliaFormControl>
              <CotroliaAutocomplete
                placeholder="Rechercher une adresse..."
                value=""
                onChange={() => {}}
                getSuggestions={() =>
                  new Promise((resolve, reject) => {
                    resolve();
                  })
                }
                error={true}
                helperText="Vous devez saisir une adresse"
              />
            </CotroliaFormControl>

            <SyntaxHighlighter language="javascript" style={rainbow}>
              {errorExample}
            </SyntaxHighlighter>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">🚨 Règles d'implémentation</Typography>
            <ul>
              <li>
                <Typography variant="subtitle1">
                  Le composant doit toujours être dans un CotroliaFormControl
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  La méthode getSuggestions doit toujours retourner une Promise
                </Typography>
              </li>
              <li>
                <Typography variant="subtitle1">
                  Si la valeur est un objet, elle doit toujours être initialisé
                  à un objet correspondant à un élément de la réponse de le
                  fonction getSuggestions
                </Typography>
              </li>
            </ul>

            <Divider style={{ marginBottom: "1rem" }} />

            <Typography variant="h5">💻 Propriétés</Typography>
            <DocumentationPropsTable
              list={[
                {
                  name: "setValue",
                  type: "func",
                  default: "",
                  desc: "La méthode permettant de changer la valeur du parent",
                },
                {
                  name: "getSuggestions",
                  type: "func",
                  default: "",
                  desc: "La méthode permettant de récupérer les suggestions. ⚠️ Doit obligatoirement retourner une Promise",
                },
                {
                  name: "isSuggestionObject",
                  type: "bool",
                  default: "false",
                  desc: "La liste des suggestions est une liste d'objets ou simplement une liste de chaines de caractères",
                },
                {
                  name: "labelKey",
                  type: "string",
                  default: "",
                  desc: "Nom de la clé de l'objet pour accéder au libellé",
                },
              ]}
            />

            <Typography variant="subtitle1">
              <p>
                {" "}
                En plus des props ci-dessus, le composant hérite de toutes les
                props du composant{" "}
                <Link
                  href="https://v4.mui.com/api/text-field/#props"
                  target="_blank"
                >
                  TextField
                </Link>{" "}
                de Material UI.
              </p>
            </Typography>
          </Box>
        </ContentCard>
      </Content>
    </>
  );
};

export default DocAutocomplete;
